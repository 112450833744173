import { TrackEventParams } from '@mitresthen/matomo-tracker-react/lib/types';
import { Box, SxProps, Theme } from '@mui/material';
import { useCallback } from 'react';
import { useMatomoEvent } from '../hooks/useMatomoEvent';
import { Section } from '../types';
import { TagMenu } from './TagMenu';
import { ContactButton } from './ContactButton';

const sx: SxProps<Theme> = {
  justifyContent: 'center',
  padding: ({ spacing }) => spacing(0, 1),
};

const MATOMO_EVENT: TrackEventParams = {
  category: 'Navigate',
  action: 'Go to section',
};

type Props = {
  appSections: Array<Section>;
  navigate: (index: number) => void;
};

export function CollapsableMenuDesktop(props: Props) {
  const { appSections, navigate } = props;
  const trackEvent = useMatomoEvent(MATOMO_EVENT);
  const handleClick = useCallback(
    (i: number, title: string) => {
      trackEvent({ name: title });
      navigate(i);
    },
    [navigate, trackEvent]
  );

  return (
    <Box display="flex" flexWrap="wrap" gap={1} sx={sx}>
      {appSections.map((appSection, i) => {
        const { hidden, title } = appSection;
        if (hidden) {
          return null;
        }

        if ('url' in appSection && appSection.url === '/') {
          return (
            <TagMenu
              key={title}
              onClick={() => {
                window.location.href = '/';
              }}
            >
              {title}
            </TagMenu>
          );
        }

        return (
          <TagMenu
            key={title}
            isService={'viaEmail' in appSection}
            onClick={() => handleClick(i, title)}
          >
            {title}
          </TagMenu>
        );
      })}
      <ContactButton inMenu />
    </Box>
  );
}
