import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

const sx: Record<string, SxProps<Theme>> = {
  root: {
    overflow: 'hidden',
    height: 20,
  },
  container: ({ breakpoints }) => ({
    [breakpoints.up('md')]: {
      marginLeft: '-35px',
    },
  }),
};

type Props = {
  name: string;
};

export function VersionBadge(props: Props) {
  const { name } = props;
  return (
    <Box sx={sx.root}>
      <Box sx={sx.container}>
        <img
          src={`https://img.shields.io/npm/v/${name}`}
          alt={`version ${name}`}
        />
      </Box>
    </Box>
  );
}
