import { TrackEventParams } from '@mitresthen/matomo-tracker-react/lib/types';
import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { useCallback } from 'react';
import { useMatomoEvent } from '../hooks/useMatomoEvent';
import { navigate } from '../hooks/useNavigate';

const sx: Record<string, SxProps<Theme>> = {
  root: ({ breakpoints }) => ({
    alignItems: 'center',
    backgroundColor: 'rgba(128,128,128,0.1)',
    borderRadius: 1,
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: ({ spacing }) => spacing(1, 2),

    [breakpoints.down('md')]: {
      justifyContent: 'center',
      textAlign: 'center',
    },

    '&:hover': {
      backgroundColor: 'rgba(255,255,128,0.1)',
      cursor: 'pointer',
    },
  }),
  badges: ({ breakpoints }) => ({
    overflow: 'hidden',
    [breakpoints.up('md')]: {
      marginTop: 1,
    },
  }),
  name: ({ breakpoints }) => ({
    marginTop: -0.5,
    marginBottom: -1,
    [breakpoints.up('md')]: {
      maxWidth: 320,
    },
  }),
  badgeVersion: ({ breakpoints }) => ({
    [breakpoints.down('md')]: {
      marginLeft: '-78px',
    },
  }),
};

const MATOMO_EVENT: TrackEventParams = {
  category: 'Click',
  action: 'VSCode extension',
};

export type VSCodeExtensionProps = {
  id: string;
  name: string;
};

export function VSCodeExtension(props: VSCodeExtensionProps) {
  const { id, name } = props;

  const triggerEvent = useMatomoEvent(MATOMO_EVENT);

  const handleClick = useCallback(() => {
    triggerEvent({ name });
    navigate(
      `https://marketplace.visualstudio.com/items?itemName=iulian-radu-at.${id}`
    );
  }, [id, name, triggerEvent]);

  return (
    <Box gap={1} sx={sx.root} onClick={handleClick}>
      <Box sx={sx.name}>{name}</Box>

      <Box display="flex" alignItems="center" sx={sx.badges}>
        <Box minWidth={150} sx={sx.badgeVersion}>
          <img
            src={`https://img.shields.io/vscode-marketplace/v/iulian-radu-at.${id}.svg`}
            alt="version"
          />
        </Box>

        <Box minWidth={100}>
          <img
            src={`https://img.shields.io/vscode-marketplace/i/iulian-radu-at.${id}.svg`}
            alt="installs"
          />
        </Box>

        <Box minWidth={120}>
          <img
            src={`https://img.shields.io/vscode-marketplace/r/iulian-radu-at.${id}.svg`}
            alt="rating"
          />
        </Box>
      </Box>
    </Box>
  );
}
