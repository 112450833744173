import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MasterThemeProvider } from './components/MasterThemeProvider';
import {
  MatomoProvider,
  createInstance,
} from '@mitresthen/matomo-tracker-react';

const instance = createInstance({
  urlBase: 'https://matomo-analytics.iulian-radu.eu',
  siteId: 8,
  disabled: window.location.host === 'localhost',
  // optional, default value: {}
  // any valid matomo configuration, all below are optional
  // configurations: {
  //   disableCookies: true,
  //   setSecureCookie: true,
  //   setRequestMethod: 'POST',
  // },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MasterThemeProvider>
      <div className="bg" />
      <div className="bg bg2" />
      <div className="bg bg3" />
      <MatomoProvider value={instance}>
        <App />
      </MatomoProvider>
    </MasterThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
