import './App.css';

import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { appSections } from './App.sections';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Section } from './components/Section';
import { SectionContainer } from './components/SectionContainer';
import { Privacy } from './pages/Privacy';
// import { AdSense } from './components/AdSense';
import { BusinessServices } from './pages/BusinessServices';

function App() {
  const redirectToHome = useCallback(() => {
    window.location.pathname = '/';
  }, []);

  const isPrivacyPolicy = window.location.pathname === '/privacy-policy';
  if (isPrivacyPolicy) {
    return (
      <Privacy
        isTransparent={true}
        tooltipCloseButton="Return to the main web site"
        onClose={redirectToHome}
      />
    );
  }

  const isBusinessServices = window.location.pathname === '/business-services';
  if (isBusinessServices) {
    return <BusinessServices />;
  }

  return (
    <Stack alignItems="center" direction="column" width="100%">
      <Header appSections={appSections} />
      <SectionContainer>
        {/* <Section>
          <AdSense />
        </Section> */}

        {appSections.map(({ css, description, title, ...rest }, i) => (
          <Section key={title} index={i} style={css} title={title} {...rest}>
            {description}
          </Section>
        ))}
      </SectionContainer>

      {/* <Section>
        <AdSense />
      </Section> */}
      <Footer />
    </Stack>
  );
}

export default App;
