import { Box, SxProps, Theme } from '@mui/material';
import React, { ReactNode } from 'react';

const sx: SxProps<Theme> = {
  display: {
    md: 'initial',
    xs: 'none',
  },
};

type TProps = {
  children: ReactNode;
};

export function HideXs(props: TProps) {
  const { children } = props;

  return <Box sx={sx}>{children}</Box>;
}
