import { Box, SxProps, Theme } from '@mui/material';
import React, { ReactNode } from 'react';
import { theme } from '../theme/common';
import { Strong } from './Strong';

const sx: SxProps<Theme> = {
  marginBottom: 1,
  textShadow: theme.textShadow,
};

type TProps = {
  children?: ReactNode;
  size?: number;
};

export function SectionTitle(props: TProps) {
  const { children, size = 1 } = props;

  if (!children) {
    return null;
  }

  return (
    <Box fontSize={`${size * 100}%`} sx={sx}>
      <Strong>{children}</Strong>
    </Box>
  );
}
