import { SxProps, Theme } from '@mui/material';
import { Small } from './Small';

const sx: SxProps<Theme> = {
  margin: ({ spacing }) => spacing(0.5, 0, 0, 1),
  opacity: 0.6,
  wordBreak: 'break-all',
};

export function ContactViaEmail() {
  return <Small sx={sx}>Serviceanfrage per E-Mail schicken</Small>;
}
