import { Stack, SxProps, Theme } from '@mui/material';
import { theme } from '../theme/common';
import { CollapsableMenu } from './CollapsableMenu';
import { SectionTitle } from './SectionTitle';
import { Section } from '../types';

const sx: SxProps<Theme> = {
  marginTop: ({ spacing }) => spacing(theme.margin),
  maxWidth: theme.columnWidth,
  padding: ({ spacing }) => spacing(4, 4),
  textAlign: 'center',
};

type Props = {
  appSections: Array<Section>;
};

export function Header(props: Props) {
  const { appSections } = props;
  return (
    <Stack direction="column" gap={1} sx={sx}>
      <SectionTitle size={1.2}>Iulian Radu's Business Portfolio</SectionTitle>
      <CollapsableMenu appSections={appSections} />
    </Stack>
  );
}
