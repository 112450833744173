import { CSSProperties } from 'react';

const sx: CSSProperties = {
  display: 'block',
  maxHeight: 75, // '100%',
  maxWidth: '100%',
};

type TProps = {
  children: string;
  style?: CSSProperties;
  title: string;
};

export function SectionImage(props: TProps) {
  const { children, style = {}, title } = props;
  return <img alt={title} src={children} style={{ ...sx, ...style }} />;
}
