import EmailIcon from '@mui/icons-material/Email';
import { Button, SxProps } from '@mui/material';

const sx: SxProps = {
  fontSize: 'small',
  textTransform: 'none',
  '& svg': {
    width: 14,
    height: 14,
  },
  '&.inMenu': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '&.inMenu:hover': {
    color: '#ffffff',
  },
};

type Props = {
  inMenu?: boolean;
};

export function ContactButton(props: Props) {
  const { inMenu } = props;
  return (
    <Button
      href="mailto:iulian.radu@gmx.at?subject=Contact+from+website"
      className={inMenu ? 'inMenu' : undefined}
      sx={sx}
    >
      <EmailIcon />
      Contact
    </Button>
  );
}
