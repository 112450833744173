import { Stack } from '@mui/material';
import { VSCodeExtension, VSCodeExtensionProps } from './VSCodeExtension';

type Extensions = Array<VSCodeExtensionProps>;

const extensions: Extensions = [
  {
    id: 'taskfile-launcher',
    name: 'A launcher for tasks in a Taskfile.yml',
  },
  {
    id: 'merged-git-branches',
    name: 'Displays in which branches is merged a git branch',
  },
  {
    id: 'find-unused-exports',
    name: 'Find unused exports',
  },
  {
    id: 'fix-relative-imports-to-baseurl',
    name: 'Fix relative imports to baseUrl',
  },
  {
    id: 'save-restore-editors',
    name: 'Save and restore tabs',
  },
  {
    id: 'sharable-kanban-boards',
    name: 'Shareable Kanban boards',
  },
  {
    id: 'vscode-tasks-sidebar',
    name: 'VSCode tasks in sidebar',
  },
];

export function VSCodeExtensions() {
  return (
    <Stack gap={1}>
      {extensions.map((extension) => (
        <VSCodeExtension key={extension.id} {...extension} />
      ))}
    </Stack>
  );
}
