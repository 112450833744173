import { Box, Link, SxProps, Theme } from '@mui/material';

const sx: Record<string, SxProps<Theme>> = {
  img: {
    width: 'auto',
    height: '1.2em',
  },
  link: {
    display: 'flex',
  },
};

type Props = {
  image: string;
  title: string;
  url: string;
};

export function IconLink(props: Props) {
  const { image, title, url } = props;

  return (
    <Link
      href={url}
      target="_blank"
      rel="noreferrer"
      sx={sx.link}
      title={title}
    >
      <Box component="img" src={image} alt={title} sx={sx.img} />
    </Link>
  );
}
