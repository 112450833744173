import { ReadMore } from './ReadMore';
import { IconLink } from './IconLink';
import { Box } from '@mui/material';
import { SxProps } from '@mui/material';
import { Small } from './Small';
import { SectionLinks } from '../types';

const sx: SxProps = {
  opacity: 0.6,
  wordBreak: 'keep-all',
};

type Props = {
  links?: SectionLinks;
  url?: string;
};

export function ReadMoreIconLinks(props: Props) {
  const { links, url } = props;

  if (links) {
    return (
      <Box
        display="flex"
        flexWrap="wrap"
        gap={2}
        marginTop={1}
        alignItems="center"
      >
        <Small sx={sx}>For more visit</Small>
        {links.map((link) => (
          <IconLink key={link.url} {...link} />
        ))}
      </Box>
    );
  }

  if (url) {
    return <ReadMore url={url} />;
  }

  return null;
}
