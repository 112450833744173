export function navigate(url: string) {
  if (url.includes('.iulian-radu.eu/')) {
    window.location.assign(url);
    return;
  }

  if (url.includes('mailto:')) {
    window.open(url, '_self');
    return;
  }

  window.open(url, '_blank');
}
