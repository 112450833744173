import { ThemeOptions } from '@mui/material';

export const commonTheme: ThemeOptions = {
  typography: {
    fontSize: 16,
  },
};

export const theme = {
  columnWidth: 800,
  margin: 2,
  textShadow: '1px 1px 2px black',
};
