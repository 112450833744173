import { Box, Grid, Stack } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { theme } from '../theme/common';
import { AppStoreBadge } from './AppStoreBadge';
import { GooglePlayBadge } from './GooglePlayBadge';

const sx: Record<string, SxProps<Theme>> = {
  name: {
    fontWeight: 'bold',
    textShadow: theme.textShadow,
  },
  description: {
    fontSize: '80%',
  },
  badges: {
    paddingTop: '4px',
  },
  badge: {
    overflow: 'hidden',
    textAlign: 'center',
    height: 70,
  },
};

type Props = {
  androidAppId?: string;
  androidPcampaignId?: string;
  appleAppId?: string;
  description?: string;
  name: string;
};

export function MobileApp(props: Props) {
  const { androidAppId, androidPcampaignId, appleAppId, description, name } =
    props;

  return (
    <Stack>
      <Box sx={sx.name}>{name}</Box>
      {description && <Box sx={sx.description}>{description}</Box>}

      <Grid container spacing={3} sx={sx.badges}>
        <Grid item xs={6} sx={sx.badge}>
          {renderAndroidBadge(name, androidAppId, androidPcampaignId)}
        </Grid>
        <Grid item xs={6} sx={sx.badge}>
          {renderIosBadge(name, appleAppId)}
        </Grid>
      </Grid>
    </Stack>
  );
}

function renderAndroidBadge(
  name: string,
  appId?: string,
  pcampaignid?: string
) {
  if (appId === undefined) {
    return null;
  }

  return (
    <GooglePlayBadge
      name={name}
      appId={appId}
      pcampaignid={pcampaignid ?? ''}
    />
  );
}

function renderIosBadge(name: string, appId?: string) {
  if (appId === undefined) {
    return null;
  }

  return <AppStoreBadge name={name} appId={appId} />;
}
