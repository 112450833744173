import { Box, SxProps, Theme } from '@mui/material';
import { useCallback } from 'react';
import { theme } from '../theme/common';
import { CollapsableMenuDesktop } from './CollapsableMenuDesktop';
import { CollapsableMenuMobile } from './CollapsableMenuMobile';
import { getAnchorName } from './Section.utils';
import { Section } from '../types';

const sx: SxProps<Theme> = {
  display: { xs: 'block', sm: 'none' },
  position: 'absolute',
  right: ({ spacing }) => spacing(theme.margin),
};

type Props = {
  appSections: Array<Section>;
};

export function CollapsableMenu(props: Props) {
  const { appSections } = props;

  const navigate = useCallback((index: number) => {
    const anchor = getAnchorName(index);
    if (anchor === undefined) {
      return;
    }

    const el = document.getElementById(anchor);
    el?.scrollIntoView();
  }, []);

  return (
    <>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <CollapsableMenuDesktop navigate={navigate} appSections={appSections} />
      </Box>
      <Box sx={sx}>
        <CollapsableMenuMobile navigate={navigate} appSections={appSections} />
      </Box>
    </>
  );
}
