import { TrackEventParams } from '@mitresthen/matomo-tracker-react/lib/types';
import { Link } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useMatomoEvent } from '../hooks/useMatomoEvent';

const MATOMO_EVENT: TrackEventParams = {
  category: 'Click',
  action: 'AppStore app',
};

type Props = {
  appId: string;
  name: string;
};

// TBD
export function AppStoreBadge(props: Props) {
  const { appId, name } = props;

  const href = useMemo(
    () => `https://apps.apple.com/us/app/${appId}/${appId}`,
    [appId]
  );

  const triggerEvent = useMatomoEvent(MATOMO_EVENT);
  const handleClick = useCallback(() => {
    triggerEvent({ name });
  }, [name, triggerEvent]);

  return (
    <Link
      underline="none"
      href={href}
      target="_blank"
      rel="noreferrer"
      onClick={handleClick}
    >
      <img
        alt=""
        src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png"
        style={{ height: 40 }}
      />
    </Link>
  );
}
