import { TrackEventParams } from '@mitresthen/matomo-tracker-react/lib/types';
import { Button, Dialog, PaperProps, Stack } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import { useMatomoEvent } from '../hooks/useMatomoEvent';
import { Privacy } from '../pages/Privacy';
import { ContactButton } from './ContactButton';
// import { Inprint } from './Inprint';
// import { InprintInfo } from './InprintInfo';
import { Small } from './Small';

const sx: SxProps = {
  textTransform: 'none',
};

const paperProps: PaperProps = {
  sx: {
    maxHeight: '70vh',
  },
};

const MATOMO_EVENT: TrackEventParams = {
  category: 'Display',
  action: 'Show',
};

enum DialogType {
  Privacy,
  // Inprint,
}

export function FooterMenu() {
  const [show, setShow] = useState<DialogType | undefined>();

  const trackEvent = useMatomoEvent(MATOMO_EVENT);

  const handleClick = useCallback(
    (dialog: DialogType, name: string) => {
      trackEvent({ name });
      setShow(dialog);
    },
    [trackEvent]
  );

  const handleClickPrivacy = useCallback(() => {
    handleClick(DialogType.Privacy, 'Privacy policy');
  }, [handleClick]);

  // const handleClickInprint = useCallback(() => {
  //   handleClick(DialogType.Inprint, 'Inprint');
  // }, [handleClick]);

  const handleClose = useCallback(() => {
    setShow(undefined);
  }, []);

  return (
    <>
      <Stack direction="row" gap={3} justifyContent="center" id="_9999">
        <ContactButton />

        <Button onClick={handleClickPrivacy} sx={sx}>
          <Small>Privacy policy</Small>
        </Button>

        {/* <Inprint onShow={handleClickInprint} /> */}
      </Stack>

      <Dialog
        open={show !== undefined}
        maxWidth="md"
        PaperProps={paperProps}
        onClose={handleClose}
      >
        {show === DialogType.Privacy && <Privacy onClose={handleClose} />}
        {/* {show === DialogType.Inprint && <InprintInfo onClose={handleClose} />} */}
      </Dialog>
    </>
  );
}
