import { BrowserAddons } from './components/BrowserAddons';
import { MobileApps } from './components/MobileApps';
import { NpmPackages } from './components/NpmPackages';
import { VSCodeExtensions } from './components/VSCodeExtensions';
import { Section } from './types';

export const appSections: Array<Section> = [
  // {
  //   description:
  //     'Da finden Sie alle meine Dienstleistungen für junge Unternehmer, Künstlern und Unternehmen die Software entwickeln.',
  //   image: '/resources/BusinessServices.png',
  //   title: 'Business Services',
  //   url: '/business-services',
  // },
  {
    description:
      'Unterstützung bei der technischen Einrichtung und Rekrutierung für den Start eines neuen ReactJS-Projekts.',
    image: '/resources/reactjs.svg',
    title: 'Setup React Projekt/Team',
    viaEmail: true,
  },
  {
    description:
      '... für Jungunternehmer. Ein Überblick darüber, was ein Dienstleistungsunternehmen in Österreich bedeutet.',
    image: '/resources/advice-startup.png',
    title: 'Unternehmensgründungsberatung',
    viaEmail: true,
  },
  {
    description:
      'Hilfe zur Cashflow-Berechnung eines Dienstleistungsunternehmens für Jungunternehmer.',
    image: '/resources/cash-flow.png',
    title: 'Cashflow-Berechnung',
    viaEmail: true,
  },
  {
    description:
      'Produktion von Videos zur Werbung für Produkte oder Dienstleistungen.',
    image: '/resources/product-video.png',
    title: 'Produktvideo',
    viaEmail: true,
  },
  {
    // css: {
    //   backgroundColor: 'white',
    //   padding: 4,
    // },
    description:
      'Different print-on-demand shops selling our custom logos on clothes and other articles.',
    image: '/resources/legacy-design-logo.png',
    links: [
      {
        image: 'resources/redbubble-logo.png',
        title: 'redbubble',
        url: 'https://www.redbubble.com/de/people/Legacy--Design/shop',
      },
      {
        image: 'resources/society6-logo.png',
        title: 'society6',
        url: 'https://society6.com/legacy-design',
      },
    ],
    title: 'Print-on-demand Shops',
  },
  // {
  //   description:
  //     'Vermittlung zwischen Künstlern, beispielsweise Musikern, und Veranstaltungsorten, beispielsweise Restaurants.',
  //   image: '/resources/guitar.png',
  //   title: 'Künstlervermittlung',
  //   viaEmail: true,
  // },
  {
    description: 'Visual Studio Code Extensions',
    title: 'VSCode extensions',
    element: <VSCodeExtensions />,
  },
  {
    description: 'NPM packages',
    title: 'NPM packages',
    element: <NpmPackages />,
  },
  {
    description: 'Browser addons',
    title: 'Browser addons',
    element: <BrowserAddons />,
  },
  {
    description: 'Mobile apps',
    title: 'Mobile apps',
    element: <MobileApps />,
  },
  // {
  //   description:
  //     'Data foundation allows you to create a Rest/GraphQL API which is specialized in creating endpoints for data, chaining endpoints for data transformation and caching the returned data.',
  //   image: '/resources/DFLogo.png',
  //   title: 'Data foundation',
  //   url: 'https://data-foundation.iulian-radu.eu/',
  // },
  // {
  //   description:
  //     'A hub for business applications. Allows integration of ReactJs applications under a layer of security.',
  //   image: '/resources/Edgy.png',
  //   title: 'Edgy',
  //   url: 'https://edgy.iulian-radu.eu/',
  // },
  {
    description:
      'Here you can find all my public projects, including the Visual Studio Code extensions, React components, Edgy Apps.',
    image: '/resources/GitHub.png',
    title: 'GitHub',
    url: 'https://github.com/iulianraduat',
  },
  {
    description:
      'Here you can find all my public gists, including React hooks and user snippets for Visual Studio Code.',
    image: '/resources/GitHub.png',
    title: 'GitHub gists',
    url: 'https://gist.github.com/iulianraduat',
  },
  // {
  //   description:
  //     'It will help you to learn what article has a German word based on its suffix, prefix or meaning by the use of quizes.',
  //   image: '/resources/DeTricks.png',
  //   title: 'German Tricks: The Gender',
  //   url: 'https://de-tricks.iulian-radu.eu/',
  // },
  {
    description: 'Here you will find my videos on YouTube.',
    image: '/resources/YouTube.jpg',
    title: 'YouTube',
    url: 'https://www.youtube.com/@iulianraduat',
  },
  {
    description:
      'You can see here a preview of my photos with links to where you can buy them.',
    hidden: true,
    image: '/resources/Pinterest_old.png',
    title: 'Pinterest',
    url: '',
  },
  {
    css: {
      backgroundColor: 'white',
      padding: 4,
    },
    description:
      'You can visit my profile on LinkedIn if you want to see my posts and my comments.',
    image: '/resources/LinkedIn.png',
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/in/iulianradu/',
  },
  {
    description:
      'You can visit my profile on Xing if you want to see my posts and my comments.',
    image: '/resources/Xing.svg',
    title: 'Xing',
    url: 'https://www.xing.com/profile/Iulian_Radu',
  },
  {
    description:
      'You can financially support and reward my contribution to open source software or the production of new vlog podcasts and photos on Patreon.',
    image: '/resources/Patreon.png',
    title: 'Patreon',
    url: 'https://www.patreon.com/iulianraduat',
  },
  {
    description:
      'You can support my production of new vlog podcasts and photos by buying something from my Amazon wish list.',
    image: '/resources/Amazon.png',
    title: 'Amazon Wish List',
    url: 'https://www.amazon.de/hz/wishlist/ls/3R887TT1IQN3A',
  },
  {
    description: 'Access the below link to visit my Facebook page',
    image: '/resources/facebook.png',
    title: 'Facebook',
    url: 'https://www.facebook.com/iulianraduat',
  },
  {
    description: 'Access the below link to visit my Pinterest page',
    image: '/resources/pinterest.png',
    title: 'Pinterest',
    url: 'https://www.pinterest.at/iulianraduat/',
  },
  {
    description: 'Access the below link to visit my Instagram page',
    image: '/resources/instagram.png',
    title: 'Instagram',
    url: 'https://www.instagram.com/iulianraduat/',
  },
];

// const _appSections: Array<Section> = [
//   {
//     description: 'Agora',
//     hidden: true,
//     image: '',
//     title: 'Agora',
//     url: '',
//   },
//   {
//     description: 'Blog',
//     hidden: true,
//     image: '',
//     title: 'Blog',
//     url: '',
//   },
//   {
//     description: 'House of culture',
//     hidden: true,
//     image: '',
//     title: 'House of culture',
//     url: '',
//   },
//   {
//     description: 'Judo',
//     hidden: true,
//     image: '',
//     title: 'Judo',
//     url: '',
//   },
//   {
//     description: 'Lists',
//     hidden: true,
//     image: '',
//     title: 'Lists',
//     url: '',
//   },
//   {
//     description: 'Cooking with Löveta',
//     hidden: true,
//     image: '',
//     title: 'Cooking with Löveta',
//     url: '',
//   },
//   {
//     description: 'React marketplace',
//     hidden: true,
//     image: '',
//     title: 'React marketplace',
//     url: '',
//   },
//   {
//     description: 'Minifigures interior design',
//     hidden: true,
//     image: '',
//     title: 'Minifigures interior design',
//     url: '',
//   },
//   {
//     description: 'UI design - Figma re-created',
//     hidden: true,
//     image: '',
//     title: 'UI design - Figma re-created',
//     url: '',
//   },
// ];
