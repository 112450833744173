import { useMatomo } from "@mitresthen/matomo-tracker-react";
import { TrackEventParams } from "@mitresthen/matomo-tracker-react/lib/types";
import { useCallback } from "react";

export function useMatomoEvent(
  args: TrackEventParams
): (newArgs?: Partial<TrackEventParams>) => void {
  const { trackEvent } = useMatomo();
  return useCallback(
    (newArgs?: Partial<TrackEventParams>) => {
      trackEvent({ ...args, ...newArgs });
    },
    [args, trackEvent]
  );
}
