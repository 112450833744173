type Props = {
  name: string;
};

export function WeeklyDownloadsBadge(props: Props) {
  const { name } = props;
  return (
    <img
      src={`https://img.shields.io/npm/dw/${name}`}
      alt={`weekly downloads ${name}`}
      style={{ maxHeight: 20 }}
    />
  );
}
