import { Browser } from './BrowserAddons.types';

type Props = {
  browser: Browser;
};

export function BrowserLogo(props: Props) {
  const { browser } = props;
  return <img alt="" src={getBrowserLogo(browser)} style={{ height: 20 }} />;
}

function getBrowserLogo(browser: Browser) {
  switch (browser) {
    case Browser.Firefox:
      return `/resources/firefox_logo,_2019.svg`;
  }
}
