import { Stack } from '@mui/material';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Section } from '../components/Section';
import { SectionContainer } from '../components/SectionContainer';
import { appSections } from './BusinessServices.sections';

export function BusinessServices() {
  return (
    <Stack alignItems="center" direction="column" width="100%">
      <Header appSections={appSections} />
      <SectionContainer>
        {appSections.map(({ css, description, title, ...rest }, i) => {
          if ('url' in rest && rest.url === '/') {
            return null;
          }

          return (
            <Section key={title} index={i} style={css} title={title} {...rest}>
              {description}
            </Section>
          );
        })}
      </SectionContainer>
      <Footer />
    </Stack>
  );
}
