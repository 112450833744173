import { Stack, SxProps, Theme } from '@mui/material';
import { CSSProperties, useMemo } from 'react';
import { theme } from '../theme/common';
import { FooterMenu } from './FooterMenu';
import { Small } from './Small';

const sx: SxProps<Theme> = {
  margin: ({ spacing }) => spacing(theme.margin, 0),
  textAlign: 'center',
};

const sxAnchor: CSSProperties = {
  color: 'inherit',
  textDecoration: 'none',
};

export function Footer() {
  const year = useMemo(() => new Date().getFullYear(), []);
  return (
    <Stack gap={1} sx={sx}>
      <FooterMenu />

      <Small>
        <a href="mailto:iulian.radu@gmx.at" style={sxAnchor}>
          {year} &copy; Iulian Radu <small>&lt;iulian.radu@gmx.at&gt;</small>
        </a>
      </Small>
    </Stack>
  );
}
