import { Box, SxProps, Theme } from '@mui/material';
import React, { Children, cloneElement, ReactElement, ReactNode } from 'react';

const sxOdd: SxProps<Theme> = {
  backgroundColor: 'rgba(128, 128, 128, 0.05)',
  display: 'flex',
  justifyContent: 'center',
};

const sxEven: SxProps<Theme> = {
  backgroundColor: 'rgba(128, 128, 128, 0.07)',
  display: 'flex',
  justifyContent: 'center',
};

type TProps = {
  children: ReactNode;
};

export function SectionContainer(props: TProps) {
  const { children } = props;

  return (
    <Box>
      {Children.toArray(children)
        .filter(isVisible)
        .map((child, i) => (
          <Box key={i} sx={i % 2 ? sxOdd : sxEven}>
            {render(child, i)}
          </Box>
        ))}
    </Box>
  );
}

function isVisible(child: any): boolean {
  return !child.props?.hidden;
}

function render(child: ReactNode, i: number) {
  if (i % 2 === 0) {
    return child;
  }

  if (typeof child !== 'object') {
    return child;
  }

  return cloneElement(child as ReactElement, { reverse: true });
}
