import { TrackEventParams } from '@mitresthen/matomo-tracker-react/lib/types';
import { Link } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useMatomoEvent } from '../hooks/useMatomoEvent';

const MATOMO_EVENT: TrackEventParams = {
  category: 'Click',
  action: 'GooglePlay app',
};

type Props = {
  appId: string;
  name: string;
  pcampaignid: string;
};

export function GooglePlayBadge(props: Props) {
  const { appId, name, pcampaignid } = props;

  const href = useMemo(
    () =>
      `https://play.google.com/store/apps/details?id=eu.iulian_radu.${appId}&pcampaignid=pcampaignid${pcampaignid}`,
    [appId, pcampaignid]
  );

  const triggerEvent = useMatomoEvent(MATOMO_EVENT);
  const handleClick = useCallback(() => {
    triggerEvent({ name });
  }, [name, triggerEvent]);

  return (
    <Link
      underline="none"
      href={href}
      target="_blank"
      rel="noreferrer"
      onClick={handleClick}
    >
      <img
        alt="Get it on Google Play"
        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        style={{ height: 50 }}
      />
    </Link>
  );
}
