import { CssBaseline, ThemeProvider } from '@mui/material';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { darkTheme } from '../theme/dark';
import { lightTheme } from '../theme/light';

type TProps = {
  children: ReactNode;
};

export function MasterThemeProvider(props: TProps) {
  const { children } = props;

  const prefersColorSchemeDark = useMemo(
    () =>
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches,
    []
  );

  const [isDarkTheme, setDarkTheme] = useState<boolean>(prefersColorSchemeDark);
  useEffect(
    () =>
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', ({ matches }) => setDarkTheme(matches)),
    []
  );

  return (
    <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
