import { Chip, SxProps, Theme } from '@mui/material';

const sx: SxProps<Theme> = {
  borderRadius: 2,
  opacity: 0.5,

  '&:hover': {
    opacity: 1,
  },
};

const sxService: SxProps<Theme> = {
  ...sx,
  backgroundColor: '#1c085d60',
};

type TProps = {
  children: string;
  isService?: boolean;
  onClick: () => void;
};

export function TagMenu(props: TProps) {
  const { children, isService, onClick } = props;

  return (
    <Chip
      label={children}
      size="small"
      sx={isService ? sxService : sx}
      variant="outlined"
      onClick={onClick}
    />
  );
}
