import { Menu as MenuIcon } from '@mui/icons-material';
import {
  Box,
  IconButton,
  PaperProps,
  Popover,
  PopoverOrigin,
  SxProps,
  Theme,
} from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { CollapsableMenuDesktop } from './CollapsableMenuDesktop';
import { Section } from '../types';

const sx: SxProps<Theme> = {
  padding: ({ spacing }) => spacing(2, 1),
};

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const paperProps: PaperProps = {
  sx: {
    backgroundColor: 'rgba(5,16,85,0.95)',
    justifyContent: 'center',
  },
};

type Props = {
  appSections: Array<Section>;
  navigate: (index: number) => void;
};

export function CollapsableMenuMobile(props: Props) {
  const { appSections, navigate } = props;

  const anchorEl = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleNavigate = useCallback(
    (index: number) => {
      handleClose();
      setTimeout(() => {
        navigate(index);
      }, 1);
    },
    [handleClose, navigate]
  );

  return (
    <>
      <IconButton
        ref={anchorEl}
        size="small"
        aria-label="Menu button"
        onClick={handleToggle}
      >
        <MenuIcon fontSize="small" />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl.current}
        anchorOrigin={anchorOrigin}
        anchorPosition={{ left: 0, top: -50 }}
        PaperProps={paperProps}
        transformOrigin={transformOrigin}
        onClose={handleClose}
      >
        <Box sx={sx}>
          <CollapsableMenuDesktop
            navigate={handleNavigate}
            appSections={appSections}
          />
        </Box>
      </Popover>
    </>
  );
}
