import { Box } from '@mui/material';
import { BrowserAddonBadge } from './BrowserAddonsBadge';
import { Browser } from './BrowserAddons.types';

const packages: Array<[Browser, string, string]> = [
  [Browser.Firefox, 'TL Which Query', 'tl-which-query'],
];

export function BrowserAddons() {
  return (
    <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center">
      {packages.map(([browser, name, id]) => (
        <BrowserAddonBadge
          key={`${browser} -- ${id}`}
          browser={browser}
          name={name}
          id={id}
        />
      ))}
    </Box>
  );
}
