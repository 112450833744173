import { Box } from '@mui/material';
import { NpmPackageBadge } from './NpmPackageBadge';

const packages: Array<string> = [
  'checkable-material-ui',
  'collapsible-split-layout',
  'digital-digit',
  'editable-dnd-list',
  'filter-material-ui',
  'generic-dnd-list',
  'icon-with-tooltip',
  'input-material-ui',
  'ios-switch-material-ui',
  'labeled-checkbox-material-ui',
  'labeled-switch-material-ui',
  'list-of-todos',
  'pattern-string-material-ui',
  'react-autosync-height',
  'react-consent-gdpr',
  'react-select-material-ui',
  'responsive-css-material-ui',
  'responsive-img-material-ui',
  'select-timezone-material-ui',
  'themed-analog-clock',
  'themed-digital-clock',
  'wip-component',
];

export function NpmPackages() {
  return (
    <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center">
      {packages.map((name) => (
        <NpmPackageBadge key={name} name={name} />
      ))}
    </Box>
  );
}
