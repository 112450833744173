import { Section } from '../types';

export const appSections: Array<Section> = [
  {
    description:
      'Vermittlung zwischen Künstlern, beispielsweise Musikern, und Veranstaltungsorten, beispielsweise Restaurants.',
    image: '/resources/guitar.png',
    title: 'Künstlervermittlung',
  },
  {
    description: 'Zurück zur Hauptwebsite.',
    title: 'Hauptwebsite',
    url: '/',
  },
];
