import { TrackEventParams } from '@mitresthen/matomo-tracker-react/lib/types';
import { Box, Stack } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { useCallback } from 'react';
import { useMatomoEvent } from '../hooks/useMatomoEvent';
import { navigate } from '../hooks/useNavigate';
import { VersionBadge } from './VersionBadge';
import { WeeklyDownloadsBadge } from './WeeklyDownloadsBadge';

const sx: Record<string, SxProps<Theme>> = {
  root: {
    alignItems: 'center',
    backgroundColor: 'rgba(128,128,128,0.1)',
    borderRadius: 1,
    display: 'inline-flex',
    gap: 1,
    padding: 1,

    '&:hover': {
      backgroundColor: 'rgba(255,255,128,0.1)',
      cursor: 'pointer',
    },
  },
  name: ({ breakpoints }) => ({
    [breakpoints.up('md')]: {
      fontSize: '80%',
    },
  }),
};

const MATOMO_EVENT: TrackEventParams = {
  category: 'Click',
  action: 'NPM package',
};

type Props = {
  name: string;
};

export function NpmPackageBadge(props: Props) {
  const { name } = props;

  const triggerEvent = useMatomoEvent(MATOMO_EVENT);

  const handleClick = useCallback(() => {
    triggerEvent({ name });
    navigate(`https://www.npmjs.com/package/${name}`);
  }, [name, triggerEvent]);

  return (
    <Stack sx={sx.root} onClick={handleClick}>
      <Box sx={sx.name}>{name}</Box>

      <Stack direction="row" gap={1}>
        <VersionBadge name={name} />
        <WeeklyDownloadsBadge name={name} />
      </Stack>
    </Stack>
  );
}
