import { createTheme } from '@mui/material';
import { commonTheme } from './common';

export const lightTheme = createTheme({
  ...commonTheme,
  palette: {
    background: {
      default: '#ff8000',
    },
    text: {
      primary: '#ffffff',
    },
  },
});
