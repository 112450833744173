import { Stack } from '@mui/material';
import { MobileApp } from './MobileApp';

export function MobileApps() {
  return (
    <Stack>
      <MobileApp
        name="Only Budgets"
        description="A self-help app for money management through the use of budgets."
        androidAppId="only_budgets"
        androidPcampaignId="MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        // appleAppId="???"
      />
    </Stack>
  );
}
