import { Box, SxProps, Theme } from '@mui/material';
import React, { ReactNode, useMemo } from 'react';

const _sx: SxProps<Theme> = {
  fontSize: '120%',
  fontWeight: 'bold',
};

type TProps = {
  children: ReactNode;
  sx?: SxProps<Theme>;
};

export function Strong(props: TProps) {
  const { children, sx = {} } = props;
  const newSx = useMemo(() => ({ ...sx, ..._sx }), [sx]);
  return <Box sx={newSx}>{children}</Box>;
}
