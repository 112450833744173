import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton, Paper } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties, ReactNode, useMemo } from 'react';

const sx: Record<string, SxProps<Theme>> = {
  paper: {
    positon: 'relative',
  },
  closeButton: {
    position: 'sticky',
    top: 0,
    right: 0,
    float: 'right',
  },
};

type Props = {
  children: ReactNode;
  isTransparent?: boolean;
  onClose: () => void;
  tooltipCloseButton: string;
};

export function DialogContent(props: Props) {
  const { children, isTransparent, onClose, tooltipCloseButton } = props;

  const stylePaper: CSSProperties | undefined = useMemo(
    () => (isTransparent ? { opacity: 0.8, paddingTop: 1 } : undefined),
    [isTransparent]
  );

  return (
    <Paper sx={sx.paper} style={stylePaper}>
      <IconButton
        color="primary"
        sx={sx.closeButton}
        title={tooltipCloseButton}
        onClick={onClose}
      >
        <CancelIcon />
      </IconButton>

      <Box px={4} pb={2} sx={{ fontSize: '80%' }}>
        {children}
      </Box>
    </Paper>
  );
}
