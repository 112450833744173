import { Link } from '@mui/material';
import { CSSProperties } from 'react';
import { DialogContent } from '../components/DialogContent';

const sxUl: CSSProperties = {
  padding: '0 16px',
  listStyleType: 'circle',
};

type Props = {
  isTransparent?: boolean;
  onClose: () => void;
  tooltipCloseButton?: string;
};

export function Privacy(props: Props) {
  const { isTransparent, onClose, tooltipCloseButton } = props;
  return (
    <DialogContent
      isTransparent={isTransparent}
      tooltipCloseButton={tooltipCloseButton ?? `Close "Privacy policy" dialog`}
      onClose={onClose}
    >
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy describes how THIS WEB SITE and OUR APPS collects,
        uses and discloses information, and what choices you have with respect
        to the information.
      </p>
      <p>
        When we refer to "<strong>THIS WEB SITE</strong>", we mean the
        iulian-radu.eu and its owner that acts as the controller and the
        processor of your information, as explained in more detail in the
        "Identifying the Data Controller and Processor" section below.
      </p>
      <p>
        When we refer to "<strong>OUR APPS</strong>", we mean all our Android
        and iOS applications which can be installed from the official app stores
        or from our web site.
      </p>

      <h2>Table of Contents:</h2>
      <ul style={{ ...sxUl, marginTop: -16 }}>
        <li>
          <Link href="#applicability">
            Applicability of this Privacy Policy
          </Link>
        </li>
        <li>
          <Link href="#collect">Information We Collect and Receive</Link>
        </li>
        <li>
          <Link href="#information">How We Use Information</Link>
        </li>
        <li>
          <Link href="#retention">Data Retention</Link>
        </li>
        <li>
          <Link href="#disclose">How We Share and Disclose Information</Link>
        </li>
        <li>
          <Link href="#security">Security</Link>
        </li>
        <li>
          <Link href="#limitations">Age Limitations</Link>
        </li>
        <li>
          <Link href="#changes">Changes to this Privacy Policy</Link>
        </li>
        <li>
          <Link href="#international">
            International Data Transfers: Privacy Shield and Contractual Terms
          </Link>
        </li>
        <li>
          <Link href="#dpo">Data Protection Officer</Link>
        </li>
        <li>
          <Link href="#identifying">
            Identifying the Data Controller and Processor
          </Link>
        </li>
        <li>
          <Link href="#rights">Your Rights</Link>
        </li>
        <li>
          <Link href="#dpa">Data Protection Authority</Link>
        </li>
        <li>
          <Link href="#contact">Contacting Us</Link>
        </li>
      </ul>

      <h2 id="applicability">Applicability of This Privacy Policy</h2>
      <p>
        This Privacy Policy applies to THIS WEB SITE’s services (collectively,
        the "<strong>Services</strong>"), iulian-radu.eu (the "
        <strong>Website</strong>") and other interactions you may have with us.{' '}
        <strong style={{ color: 'red' }}>
          If you do not agree with the terms, do not access or use the Services,
          Website, the Apps or any other aspect of our business.
        </strong>
      </p>
      <p>
        This Privacy Policy does not apply to any third party applications or
        software that integrate with the Services through our platform ("
        <strong>Third Party Services</strong>"), or any other third party
        products, services or businesses. In addition, a separate agreement
        governs delivery, access and use of the Services (the "
        <strong>Customer Agreement</strong>"), including the processing of any
        content submitted through Services accounts (collectively, "
        <strong>Customer Data</strong>"). Please check the Privacy Policies
        applied to these applications and web sites.
      </p>

      <h2 id="collect">Information We Collect And Receive</h2>
      <p>
        We may collect and receive Customer Data and other information and data
        ("
        <strong>Other Information</strong>") in a variety of ways:
      </p>
      <p>
        <strong>Customer Data</strong>
      </p>
      <ul style={sxUl}>
        <li>
          Customers or individuals granted access to our Services ("
          <strong>Authorized Users</strong>") routinely submit Customer Data to
          us when using the Services
        </li>
      </ul>
      <p>
        <strong>Other Information</strong>
        <br />
        We also may collect, generate and/or receive Other Information, like:
      </p>
      <ul style={sxUl}>
        <li>
          <strong>Services Metadata</strong>
          <div>
            When an Authorized User interacts with the Services, metadata is
            generated that provides additional context about the way Authorized
            Users work.
          </div>
        </li>
        <li>
          <strong>Log data</strong>
          <div>
            As with most websites and technology services delivered over the
            Internet, our servers automatically collect information when you
            access or use our Website or Services and record it in log files.
            This log data may include the Internet Protocol (IP) address, the
            address of the web page visited before using the Website or
            Services, browser type and settings, the date and time the Services
            were used, information about browser configuration and plugins,
            language preferences and cookie data.
          </div>
        </li>
        <li>
          <strong>Location information</strong>
          <div>
            We may receive location information from devices in accordance with
            the consent process provided by your device, but we do not log or
            use it.
          </div>
        </li>
        <li>
          <strong>Cookie Information</strong>
          <div>
            THIS WEB SITE uses only cookies found under our own control, but
            which can access and provide information to other web sites found
            under our controll.
          </div>
        </li>
        <li>
          <strong>Third Party Data</strong>
          <div>
            We may receive data about organizations, industries, Website
            visitors, marketing campaigns and other matters related to our
            business from affiliates and subsidiaries, our partners or others
            that we use to make our own information better or more useful. This
            data may be combined with Other Information we collect and might
            include aggregate level data, such as which IP addresses correspond
            to zip codes or countries. Or it might be more specific: for
            example, how well an online marketing or email campaign performed.
          </div>
        </li>
        <li>
          <strong>Additional Information Provided to Us</strong>
          <div>
            We may receive Other Information when submitted to our Website or if
            you participate in a focus group, contest, activity or event, apply
            for a job, request support, interact with our social media accounts
            or otherwise communicate to us.
          </div>
        </li>
      </ul>
      <p>
        Generally, no one is under a statutory or contractual obligation to
        provide any Customer Data or Other Information (collectively, "
        <strong>Information</strong>"). However, certain Information is
        collected automatically and, if some Information is not provided, we may
        be unable to provide the Services.
      </p>
      <h2 id="information">How We Use Information</h2>
      <p>
        Customer Data will be used by us in accordance with Customer’s
        instructions, including any applicable terms in the Customer Agreement,
        and as required by applicable law.{' '}
        <strong>We are a processor of Customer Data.</strong>
      </p>
      <p>
        We use Other Information in furtherance of our legitimate interests in
        operating our Services, Website and business. More specifically, We use
        Other Information:
      </p>
      <ul style={sxUl}>
        <li>
          <strong>
            To provide, update, maintain and protect our Services, Website and
            business.
          </strong>{' '}
          This includes use of Other Information to support delivery of the
          Services under a Customer Agreement, prevent or address service
          errors, security or technical issues, analyze and monitor usage,
          trends and other activities or at an Authorized User’s request.
        </li>
        <li>
          <strong>
            As required by applicable law, legal process or regulation.
          </strong>
        </li>
        <li>
          <strong>
            To communicate with you by responding to your requests, comments and
            questions.
          </strong>{' '}
          If you contact us, we may use your Other Information to respond.
        </li>
        <li>
          <strong>
            To develop and provide search, learning and productivity tools and
            additional features.
          </strong>{' '}
          We try to make the Services as useful as possible for our Customers
          and our Authorized Users.
        </li>
        <li>
          <strong>To send emails and other communications.</strong> We may send
          you service, technical and other administrative emails, messages and
          other types of communications. We may also contact you to inform you
          about changes in our Services, our Services offerings, and important
          Services-related notices, such as security and fraud notices. These
          communications are considered part of the Services and you may not opt
          out of them. In addition, we sometimes send emails about new product
          features, promotional communications or other news about THIS WEB
          SITE. These are marketing messages so you can control whether you want
          to receive them or not.
        </li>
        <li>
          <strong>
            For billing, account management and other administrative matters.
          </strong>{' '}
          We may need to contact you for invoicing, account management and
          similar reasons and we use account data to administer accounts and
          keep track of billing and payments.
        </li>
        <li>
          <strong>
            To investigate and help prevent security issues and abuse.
          </strong>
        </li>
      </ul>
      <p>
        If Information is aggregated or de-identified so it is no longer
        reasonably associated with an identified or identifiable natural person,
        we may use it for any business purpose. To the extent Information is
        associated with an identified or identifiable natural person and is
        protected as personal data under applicable data protection law, it is
        referred to in this Privacy Policy as "<strong>Personal Data</strong>."
      </p>

      <h2 id="retention">Data Retention</h2>
      <p>
        We will retain Customer Data in accordance with a Customer’s
        instructions, including any applicable terms in the Customer Agreement,
        and as required by applicable law. The deletion of Customer Data and
        other use of the Services by Customer may result in the deletion and/or
        de-identification of certain associated Other Information. We may retain
        Other Information pertaining to you for as long as necessary for the
        purposes described in this Privacy Policy. This may include keeping your
        Other Information after you have deactivated your account for the period
        of time needed for us to pursue legitimate business interests, conduct
        audits, comply with and demonstrate compliance with legal obligations,
        resolve disputes and enforce our agreements.
      </p>

      <h2 id="disclose">How We Share And Disclose Information</h2>
      <p>
        We try do not share or disclose Information with any other 3rd party.
      </p>
      <ul style={sxUl}>
        <li>
          <strong>Corporate Affiliates.</strong> THIS WEB SITE may share Other
          Information with its corporate affiliates, parents and/or
          subsidiaries.
        </li>
        <li>
          <strong>During a Change of Business.</strong> If we engage in a
          merger, acquisition, bankruptcy, dissolution, reorganization, sale of
          some or all of our assets or stock, financing, public offering of
          securities, acquisition of all or a portion of our business, a similar
          transaction or proceeding, or steps in contemplation of such
          activities (e.g. due diligence), some or all Other Information may be
          shared or transferred, subject to standard confidentiality
          arrangements.
        </li>
        <li>
          <strong>Aggregated or De-identified Data.</strong> We may disclose or
          use aggregated or de-identified Other Information for any purpose. For
          example, we may share aggregated or de-identified Other Information
          with prospects or partners for business or research purposes, such as
          telling a prospective THIS WEB SITE customer the average amount of
          time spent within the Website.
        </li>
        <li>
          <strong>To Comply with Laws.</strong> If we receive a request for
          information, we may disclose Other Information if we reasonably
          believe disclosure is in accordance with or required by any applicable
          law, regulation or legal process. You can contact us by email if you
          need to understand how we respond to requests to disclose data from
          government agencies and other sources.
        </li>
        <li>
          <strong>To enforce our rights, prevent fraud, and for safety.</strong>{' '}
          To protect and defend the rights, property or safety of our Website,
          Services or third parties, including enforcing contracts or policies,
          or in connection with investigating and preventing fraud or security
          issues.
        </li>
        <li>
          <strong>With Consent.</strong> We may share Other Information with
          third parties when we have your consent to do so.
        </li>
      </ul>

      <h2 id="security">Security</h2>
      <p>
        We take security of data very seriously. We work hard to protect Other
        Information you provide from loss, misuse, and unauthorized access or
        disclosure. These steps take into account the sensitivity of the Other
        Information we collect, process and store, and the current state of
        technology. Given the nature of communications and information
        processing technology, we cannot guarantee that Information, during
        transmission through the Internet or while stored on our systems or
        otherwise in our care, will be absolutely safe from intrusion by others.
      </p>

      <h2 id="limitations">Age Limitations</h2>
      <p>
        To the extent prohibited by applicable law, we do not allow use of our
        Services and Website by anyone younger than 18 years old. If you learn
        that anyone younger than 18 has unlawfully provided us with personal
        data, please contact us and we will take steps to delete such
        information.
      </p>

      <h2 id="changes">Changes To This Privacy Policy</h2>
      <p>
        We may change this Privacy Policy from time to time. Laws, regulations
        and industry standards evolve, which may make those changes necessary,
        or we may make changes to our business. We will post the changes to this
        page and encourage you to review our Privacy Policy to stay informed. If
        we make changes that materially alter your privacy rights, we will
        provide additional notice, such as via email or through the Services. If
        you disagree with the changes to this Privacy Policy, you should
        deactivate or remove your accounts. Please contact the Customer if you
        wish to request the removal of Personal Data under their control.
      </p>

      <h2 id="international">
        International Data Transfers: Privacy Shield And Contractual Terms
      </h2>
      <p>
        We may transfer your Personal Data to countries other than the one in
        which you live. We deploy the following safeguards if we transfer
        Personal Data originating from the European Union or Switzerland to
        other countries not deemed adequate under applicable data protection
        laws.
      </p>

      <h2 id="dpo">Data Protection Officer</h2>
      <p>
        To communicate with our Data Protection Officer, please email to Iulian
        Radu at{' '}
        <Link href="mailto:iulian.radu@gmx.at?subject=I want to contact the DPO">
          iulian.radu@gmx.at
        </Link>
        , which is our Data Protection Officer.
      </p>

      <h2 id="identifying">Identifying The Data Controller And Processor</h2>
      <p>
        Data protection law in certain jurisdictions differentiates between the
        "controller" and "processor" of information. In general, we are the
        processor of Customer Data and the controller of Other Information. And
        in general, Customer is the controller of Customer Data.
      </p>

      <h2 id="rights">Your Rights</h2>
      <p>
        Individuals located in certain countries, including the European
        Economic Area, have certain statutory rights in relation to their
        personal data. Subject to any exemptions provided by law, you may have
        the right to request access to Information, as well as to seek to
        update, delete or correct this information. You can usually do this
        using the settings and tools provided in your Services account. If you
        cannot use the settings and tools, contact Customer for additional
        access and assistance.
      </p>
      <p>
        To the extent that the processing of your Personal Data is subject to
        the General Data Protection Regulation, we rely on our legitimate
        interests, described above, to process your data. We may also process
        Other Information that constitutes your Personal Data for direct
        marketing purposes and you have a right to object to us using your
        Personal Data for this purpose at any time.
      </p>

      <h2 id="dpa">Data Protection Authority</h2>
      <p>
        Subject to applicable law, you also have the right to (i) restrict our
        use of Other Information that constitutes your Personal Data and (ii)
        lodge a complaint with your local data protection authority. If you are
        a resident of the European Economic Area and believe we maintain your
        Personal Data within the scope of the General Data Protection Regulation
        (GDPR), you may direct questions or complaints directly to to our{' '}
        <Link
          href="https://www.data-protection-authority.gv.at/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          lead supervisory authority
        </Link>
        :
      </p>
      <pre>{`Österreichische Datenschutzbehörde
Barichgasse 40-42, 
1030 Vienna,
Austria
Phone: +431521520
EMail: dsb@dsb.gv.at`}</pre>

      <h2 id="contact">Contacting Us</h2>
      <p>
        Please also feel free to contact us if you have any questions about this
        Privacy Policy or our practices, or if you are seeking to exercise any
        of your statutory rights. You may contact us at{' '}
        <Link href="mailto:iulian.radu@gmx.at?subject=Question about Privacy Policy">
          iulian.radu@gmx.at
        </Link>
        .
      </p>
    </DialogContent>
  );
}
