import { TrackEventParams } from '@mitresthen/matomo-tracker-react/lib/types';
import { Box, Grid, SxProps, Theme } from '@mui/material';
import { CSSProperties, ReactNode, useCallback, useMemo } from 'react';
import { useMatomoEvent } from '../hooks/useMatomoEvent';
import { navigate } from '../hooks/useNavigate';
import { theme } from '../theme/common';
import { HideXs } from './HideXs';
import { ReadMoreIconLinks } from './ReadMoreIconLinks';
import { getAnchorName } from './Section.utils';
import { SectionImage } from './SectionImage';
import { SectionTitle } from './SectionTitle';
import { SectionLinks } from '../types';
import { ContactViaEmail } from './ContactViaEmail';

const sx: SxProps<Theme> = {
  alignItems: 'start',
  cursor: 'pointer',
  flexGrow: 1,
  justifyContent: 'space-between',
  padding: ({ spacing }) => spacing(4, 4),
  maxWidth: theme.columnWidth,
  border: 'solid 1px transparent',
  borderBottom: 'solid 1px rgba(255,255,255,0.05)',

  '&:hover': {
    backgroundColor: 'rgba(128, 128, 128, 0.2)',
    border: 'solid 1px grey',
    borderRadius: 2,
    boxShadow: ({ shadows }) => shadows[3],
    transition: 'background-color 1s',
  },
};

const sxNoLink: SxProps<Theme> = {
  alignItems: 'start',
  flexGrow: 1,
  justifyContent: 'space-between',
  padding: ({ spacing }) => spacing(4, 4),
  maxWidth: theme.columnWidth,
  border: 'solid 1px transparent',
  borderBottom: 'solid 1px rgba(255,255,255,0.05)',
};

const MATOMO_EVENT: TrackEventParams = {
  category: 'Click',
  action: 'External web site',
};

type TProps = {
  children: ReactNode;
  element?: JSX.Element;
  hidden?: boolean;
  image?: string;
  index?: number;
  links?: SectionLinks;
  reverse?: boolean;
  style?: CSSProperties;
  title?: string;
  url?: string;
  viaEmail?: boolean;
};

export function Section(props: TProps) {
  const {
    children,
    hidden,
    element,
    image,
    index,
    links,
    reverse,
    style,
    title,
    url,
    viaEmail,
  } = props;

  const newSx: SxProps<Theme> = useMemo(() => {
    const mixSx = url || viaEmail ? sx : sxNoLink;
    return reverse ? { ...mixSx, flexDirection: 'row-reverse' } : mixSx;
  }, [reverse, url, viaEmail]);

  const textAlign = useMemo(() => (reverse ? 'right' : 'left'), [reverse]);

  const triggerEvent = useMatomoEvent(MATOMO_EVENT);

  const handleClick = useCallback(() => {
    if (url) {
      triggerEvent({ name: url });
      navigate(url);
      return;
    }

    if (viaEmail) {
      triggerEvent({ name: title });
      navigate(`mailto:iulian.radu@gmx.at`);
    }
  }, [title, triggerEvent, url, viaEmail]);

  if (hidden) {
    return null;
  }

  if (element) {
    return (
      <Grid
        container
        id={getAnchorName(index)}
        gap={0}
        sx={newSx}
        onClick={handleClick}
      >
        <Grid item xs={12} textAlign={textAlign}>
          <SectionTitle>{children}</SectionTitle>
          <Box pt={1}>{element}</Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      id={getAnchorName(index)}
      gap={0}
      sx={newSx}
      onClick={handleClick}
    >
      <Grid item md={9} xs={12} textAlign={textAlign}>
        {title && <SectionTitle>{title}</SectionTitle>}
        {children}
        <ReadMoreIconLinks url={url} links={links} />
        {viaEmail && <ContactViaEmail />}
      </Grid>
      <Grid item md={1} />
      <Grid item overflow="hidden" marginTop={6} md={2}>
        <HideXs>
          <SectionImage title={title ?? ''} style={style}>
            {image!}
          </SectionImage>
        </HideXs>
      </Grid>
    </Grid>
  );
}
