import { SxProps, Theme } from '@mui/material';
import React, { useMemo } from 'react';
import { Small } from './Small';

const sx: SxProps<Theme> = {
  margin: ({ spacing }) => spacing(0.5, 0, 0, 1),
  opacity: 0.6,
  wordBreak: 'break-all',
};

type TProps = {
  url?: string;
};

export function ReadMore(props: TProps) {
  const { url } = props;
  const text = useMemo(
    () => (url !== undefined ? `More at ${url}` : 'Comming soon'),
    [url]
  );
  return <Small sx={sx}>{text}</Small>;
}
